<template>
  <span class="flix-help">
    <a
      href="#"
      @click.prevent=""
      @mouseover="open = true"
      @mouseleave="open = false"
    >
      <span :key="open" class="flix-icon" :class="{ active: open }">
        <icon id="circle-question" />
      </span>
    </a>
    <transition name="showHelp" mode="out-in">
      <span class="flix-helptext" :key="open" v-if="open">
        <slot></slot>
      </span>
    </transition>
  </span>
</template>
<script>
export default {
  components: {},
  props: {},
  data() {
    return {
      open: false
    };
  }
};
</script>
<style lang="sass" scoped></style>
